import request from '@/api/request'

export function fetchList(params) {
    return request({
        url: '/office-service/questionnaire/questionnaireList',
        params,
    })
}
export function add(data) {
    return request({
        url: '/office-service/questionnaire/addQuestionnaire',
        method: 'post',
        data,
    })
}

export function fetchDetail({ id }) {
    return request({
        url: '/office-service/questionnaire/login/detail/' + id,
    })
}

export function fetchResult({ id }) {
    return request({
        url: '/office-service/questionnaire/result/' + id,
    })
}

export function edit(data) {
    return request({
        url: '/office-service/questionnaire/updateQuestionnaire',
        method: 'post',
        data,
    })
}

export function remove({ id }) {
    return request({
        url: '/office-service/questionnaire/delQuestionnaire/' + id,
        method: 'post',
    })
}