var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.visible = true}}},[_vm._v("新增")]),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"pagination":false}},[_c('a-table-column',{attrs:{"title":"部门","data-index":"deptName"}}),_c('a-table-column',{attrs:{"title":"姓名","data-index":"userName"}}),_c('a-table-column',{attrs:{"title":"操作","align":"center","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(index)}}},[_vm._v("删除")])]}}])})],1),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"新增评委","footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"colon":false,"width":"640px"}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('EmployeeSelector',{attrs:{"value":_vm.selectedUser.name ? [_vm.selectedUser] : []},on:{"change":(arr) => {
              if (arr.length > 0) {
                _vm.selectedUser = arr[0];
              } else {
                _vm.selectedUser = {};
              }
            }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.selectedUser.name)?_c('div',[_vm._v(" "+_vm._s(_vm.selectedUser.deptUniqueName)+"-"+_vm._s(_vm.selectedUser.name)+" ")]):_c('div',[_vm._v(" ")])])],1)],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("提交")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }