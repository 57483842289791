<template>
  <div>
    <a-button type="primary" size="small" @click="visible = true"
      >新增</a-button
    >

    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column title="部门" data-index="deptName"></a-table-column>
      <a-table-column title="姓名" data-index="userName"></a-table-column>

      <a-table-column title="操作" align="center" width="100px">
        <template slot-scope="text, record, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      :visible="visible"
      title="新增评委"
      :footer="null"
      @cancel="cancel"
    >
      <a-form :colon="false" width="640px">
        <a-form-item label="姓名">
          <EmployeeSelector
            @change="
              (arr) => {
                if (arr.length > 0) {
                  selectedUser = arr[0];
                } else {
                  selectedUser = {};
                }
              }
            "
            :value="selectedUser.name ? [selectedUser] : []"
          >
            <a-button block style="text-align: left">
              <div v-if="selectedUser.name">
                {{ selectedUser.deptUniqueName }}-{{ selectedUser.name }}
              </div>
              <div v-else>&nbsp;</div>
            </a-button>
          </EmployeeSelector>
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" @click="save">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import EmployeeSelector from "@/components/employee-selector";

export default {
  components: {
    EmployeeSelector,
  },

  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      selectedUser: {},
    };
  },
  methods: {
    cancel() {
      this.visible = false;
      this.form.resetFields();
      this.selectedUser = {};
    },

    save() {
      if (!this.selectedUser.name) {
        this.$message.error("请选择人员");
        return;
      }

      if (this.list.find((item) => item.userId === this.selectedUser.userId)) {
        this.$message.error("请勿重复选择人员");
        return;
      }
      this.list.push({
        deptId: this.selectedUser.deptId,
        deptName: this.selectedUser.deptUniqueName,
        userId: this.selectedUser.userId,
        userName: this.selectedUser.name,
      });
      this.cancel();
    },

    remove(index) {
      this.list.splice(index, 1);
    },
  },
};
</script>