<template>
  <div>
    <a-button
      type="primary"
      size="small"
      @click="visible = true"
      :disabled="disabled"
      >新增</a-button
    >
    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column title="指标名" data-index="title"></a-table-column>
      <a-table-column title="指标说明" data-index="annotation"></a-table-column>
      <a-table-column title="分值" align="center" width="100px">
        <template slot-scope="text">
          <span style="color: #1890ff">{{ text.value }}</span>
        </template>
      </a-table-column>
      <a-table-column title="权重" align="center" width="100px">
        <template slot-scope="text">
          <span style="color: #1890ff">{{ text.weight }}</span>
        </template>
      </a-table-column>
      <a-table-column title="操作" align="center" width="100px">
        <template slot-scope="text, record, index">
          <a
            href="#"
            :class="disabled ? '' : 'danger'"
            @click.prevent="remove(index)"
            :disabled="disabled"
            >删除</a
          >
        </template>
      </a-table-column>
    </a-table>

    <div class="tips">
      <a-icon type="info-circle" />
      <span>编辑时评分指标无法增删改，请创建时填写完整。</span>
    </div>

    <a-modal
      :visible="visible"
      title="新增评分指标"
      :footer="null"
      @cancel="cancel"
    >
      <a-form :form="form" :colon="false" @submit="handleSubmit" width="640px">
        <a-form-item label="指标名">
          <a-input
            v-decorator="[
              'title',
              {
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="指标说明">
          <a-input
            v-decorator="[
              'annotation',
              {
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="分值">
          <a-input-number
            :min="0"
            :max="100"
            v-decorator="[
              'value',
              {
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="权重">
          <a-input-number
            :min="0"
            :max="1"
            v-decorator="[
              'weight',
              {
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
    };
  },
  methods: {
    cancel() {
      this.visible = false;
      this.form.resetFields();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.list.push(values);
          this.cancel();
        }
      });
    },

    remove(index) {
      this.list.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #999;
  span {
    padding-left: 0.5em;
  }
}
</style>