var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-button',{attrs:{"type":"primary","size":"small","disabled":_vm.disabled},on:{"click":function($event){_vm.visible = true}}},[_vm._v("新增")]),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"pagination":false}},[_c('a-table-column',{attrs:{"title":"指标名","data-index":"title"}}),_c('a-table-column',{attrs:{"title":"指标说明","data-index":"annotation"}}),_c('a-table-column',{attrs:{"title":"分值","align":"center","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',{staticStyle:{"color":"#1890ff"}},[_vm._v(_vm._s(text.value))])]}}])}),_c('a-table-column',{attrs:{"title":"权重","align":"center","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',{staticStyle:{"color":"#1890ff"}},[_vm._v(_vm._s(text.weight))])]}}])}),_c('a-table-column',{attrs:{"title":"操作","align":"center","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a',{class:_vm.disabled ? '' : 'danger',attrs:{"href":"#","disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.remove(index)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"tips"},[_c('a-icon',{attrs:{"type":"info-circle"}}),_c('span',[_vm._v("编辑时评分指标无法增删改，请创建时填写完整。")])],1),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"新增评分指标","footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"colon":false,"width":"640px"},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"指标名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'title',
            {
              rules: [{ required: true, message: '请输入！' }],
            },
          ]),expression:"[\n            'title',\n            {\n              rules: [{ required: true, message: '请输入！' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"指标说明"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'annotation',
            {
              rules: [{ required: true, message: '请输入！' }],
            },
          ]),expression:"[\n            'annotation',\n            {\n              rules: [{ required: true, message: '请输入！' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"分值"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'value',
            {
              rules: [{ required: true, message: '请输入！' }],
            },
          ]),expression:"[\n            'value',\n            {\n              rules: [{ required: true, message: '请输入！' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"min":0,"max":100}})],1),_c('a-form-item',{attrs:{"label":"权重"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'weight',
            {
              rules: [{ required: true, message: '请输入！' }],
            },
          ]),expression:"[\n            'weight',\n            {\n              rules: [{ required: true, message: '请输入！' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"min":0,"max":1}})],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("提交")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }